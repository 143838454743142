.container {
  margin: 30px 0;
  width: 100%;
  padding: 30px;
  min-height: 100vh;
  background: #edeef0;
  color: #000000;
}

.container h1,
.container h2,
.container p {
  color: #000000;
}
.container a {
  color: blue;
}
.container a:visited {
  color: purple;
}

h1 {
  text-align: center;
  margin-bottom: 8px;
  font-weight: bold;
  font-size: 24px;
}

h2 {
  margin-bottom: 8px;
  font-weight: bold;
  font-size: 18px;
}

p {
  margin-bottom: 8px;
  font-size: 16px;
}

b {
  font-weight: bold;
}

.list {
  list-style: disc;
  margin-left: 20px;
}
